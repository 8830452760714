import { addFlashMessageWarning } from '../shared'
import $ from 'jquery'
import { scrollToTop } from '../registration/windowEvents'
import { selectors } from '../selectors'

const MESSAGES = {
    ERROR: 'SMS kód není platný.',
}

const RESPONSE_STATUS_VALIDATION = {
    ERROR: 'error',
    SUCCESS: 'success',
}

const RESPONSE_STATUS_RESEND = {
    ATTEMPTS_EXCEEDED: -1,
    SUCCESS: 1,
}

const $alertPlacement = $('.alert-box .alert-content')

export const handlePinCodeValidationResponse = (data) => {
    let submitForm
    if (data.status === RESPONSE_STATUS_VALIDATION.ERROR) {
        addFlashMessageWarning(MESSAGES.ERROR, $alertPlacement)
        scrollToTop()
        submitForm = false
    }
    if (data.status === RESPONSE_STATUS_VALIDATION.SUCCESS) {
        submitForm = true
    }

    return submitForm
}

export const handlePinCodeResendResponse = ({ message, status }) => {
    const $resendDiv = $(selectors.content.resendPinDiv)
    console.log(status)
    $resendDiv.append(`<p class="resend-pincode__message"> ${message} </p>`)

    if (status === RESPONSE_STATUS_RESEND.SUCCESS) {
        setTimeout(() => {
            $(selectors.content.resendPincodeMessage).hide()
        }, 5000)
    }

    if (status === RESPONSE_STATUS_RESEND.ATTEMPTS_EXCEEDED) {
        $(selectors.buttons.resendPinCode).unbind('click')
    }
}
